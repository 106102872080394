export default class View {
    static get() {
        return {
            title                   : "Carrière de la vie - Matthieu Barbaresco",
            default_meta_description: "Locution latine signifiant proprement « carrière (curriculum, de currere, “courir”) de la vie (vitae) »",
            my_email                : "matthieu@barbares.co",
            my_phone_label          : "06 18 60 44 97",
            my_phone_link           : "0618604497",
            my_address              : "62, rue Botzaris - 75019 PARIS",
            mailjet_url             : "https://www.mailjet.com/fr/",
            /*menus                   : {
                header_menu: {
                    items: [
                        {
                            title: "Accueil",
                            uri  : "/",
                            slug : "home"
                        }
                    ]
                }
            }*/
        };
    }
}