import env from "./env";

export default function app() {
    return {
        "protocol_app" : "https",
        "dns_app"      : env().dns,
        "url_assets"   : "https://cv.barbares.co/assets/dist",
        "url_documents": "https://cv.barbares.co/assets/documents/",
        "port_app"     : ""
    };
}